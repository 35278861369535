.container {
  display: flex;
  justify-content: space-between; /* this will push the children to the edges */
}

.row1 {
  /* display: flex; */
  margin: 2px;
}

.row2 {
  /* display: flex; */
  padding-bottom: 10px;
  margin: 4px;
}

.coursework-section,
.activities-section {
  margin-bottom: 20px;
}

.courses-container,
.activities-container {
  /* Using flex-wrap allows the course elements to wrap onto the next line */
  display: flex;
  flex-wrap: wrap;
}

.course {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block; /* This makes the element's width relative to its content */
  margin-right: 10px; /* Spacing between elements */
  margin-bottom: 10px; /* Spacing between lines */
}

/* .activities-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
} */

.activity {
  background-color: #f0f0f0;
  /* padding: 5px 10px; */
  border-radius: 5px;
  display: block; /* A block element will take the full width available */
}
